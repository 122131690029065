.light-mode-mask {
  background: url(https://res.cloudinary.com/dgv2ytq4r/image/upload/v1582031820/bubble_zlir7w.png)
    repeat;
  background-size: 300px auto;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: upward 12s infinite linear;
}
.dark-mode-mask {
  background: url(https://res.cloudinary.com/dgv2ytq4r/image/upload/v1584483079/bubble-white_ocbnqz.png)
    repeat;
  background-size: 300px auto;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: upward 12s infinite linear;
}
@keyframes upward {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -300px;
  }
}
