$red: #191919;
$black: #550fe3;
$pink: #790fe3;

.container {
  // width: 1280px;
  // min-width: 1280px;
  max-width: 1100px;
  margin: 0 auto;
  .wrapper {
    padding: 0 48px;
    // .home {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   height: 100%;
    //   width: 100%;
    //   p {
    //     font-size: 0.75rem;
    //     letter-spacing: 0.3rem;
    //     text-transform: uppercase;
    //     font-weight: 500;
    //   }
    //   h5 {
    //     margin-top: 260px;
    //     font-size: 24px;
    //     font-weight: 600;
    //     padding-right: 360px;
    //   }
    // }
  }
}

header {
  height: 100px;
  width: 100%;
  .inner-header {
    position: relative;
    z-index: 10;
    height: 80px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo h2 {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: white;
    }
    .menu {
      button {
        color: white;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 0.8rem;
        mix-blend-mode: difference;
      }
    }
  }
  .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;

    height: 100%;
    width: 80wv;
    .menu-secondary-background-color {
      background: $black;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-secondary-background-color2 {
      background: $pink;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 0;
    }
    .menu-layer {
      position: relative;
      background: $red;
      height: 100%;
      overflow: hidden;
      .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        animation-name: backgroundEffect;
        background-position: fixed;
        animation-duration: 10s;
        animation-iteration-count: infinite;
      }
      .wrapper {
        position: relative;
        .menu-links {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          position: relative;
          top: 180px;
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                font-size: 6rem;
                font-weight: 700;
                cursor: pointer;
                // height: 135px;
                overflow: hidden;
                position: relative;
                max-width: 600px;
                a {
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $black;
                  }
                }
                p {
                  color: #fff;
                  font-size: 1.5rem;
                  text-decoration: none;
                  // &:hover {
                  //   color: $black;
                  // }
                }
                h3 {
                  color: #fff;
                  font-size: 2.5rem;
                  margin: 8px auto;
                  text-align: center;
                }
              }
            }
          }
          .info {
            color: #fff;
            width: 300px;
            h3 {
              font-size: 1.2rem;
              margin: 8px auto;
            }
            p {
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }
        }
        .locations {
          // position: absolute;
          // bottom: -80px;
          color: #fff;
          margin-top: 30px;
          font-size: 0.8rem;
          font-weight: 600;
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: $black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .menu-links {
    top: 120px !important;
    nav {
      ul {
        margin: 0;
        padding: 0;
        li {
          p {
            color: #fff;
            font-size: 1.2rem !important;
            text-decoration: none;
            // &:hover {
            //   color: $black;
            // }
          }
          h3 {
            color: #fff;
            font-size: 2.2rem !important;
            margin: 8px auto;
            text-align: center;
          }
        }
      }
    }
    .locations {
      span {
        cursor: pointer;
        &:first-child {
          margin-left: 0px !important;
        }
        margin: 0 10px !important;
      }
    }
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}
