body {
  margin: 0;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

.containerfor404 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: linear-gradient(
    140deg,
    black 0%,
    #25262a 75%,
    #25262a 100%
  );
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-family: arial, sans-serif;
  overflow: hidden;
}

.content {
  position: relative;
  width: 60%;
  min-width: 400px;
  background: #25262a;
  padding: 40px;
  text-align: center;
  box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995)
    forwards;
}
.content p {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: white;
}
.content a {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: white;
}
.content p:last-child {
  margin-bottom: 0;
}
.content button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  border: 3px solid #595959;
  background: transparent;
  font-size: 1rem;
  color: #595959;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.particle {
  position: absolute;
  display: block;
  pointer-events: none;
}
.particle:nth-child(1) {
  top: 38.7409200969%;
  left: 95.5165692008%;
  font-size: 26px;
  filter: blur(0.02px);
  animation: 23s float2 infinite;
}
.particle:nth-child(2) {
  top: 12.6366950182%;
  left: 42.0332355816%;
  font-size: 23px;
  filter: blur(0.04px);
  animation: 26s float infinite;
}
.particle:nth-child(3) {
  top: 85.3333333333%;
  left: 54.6341463415%;
  font-size: 25px;
  filter: blur(0.06px);
  animation: 28s float2 infinite;
}
.particle:nth-child(4) {
  top: 17.7339901478%;
  left: 77.0750988142%;
  font-size: 12px;
  filter: blur(0.08px);
  animation: 40s float infinite;
}
.particle:nth-child(5) {
  top: 97.0588235294%;
  left: 95.4724409449%;
  font-size: 16px;
  filter: blur(0.1px);
  animation: 22s float infinite;
}
.particle:nth-child(6) {
  top: 87.0415647922%;
  left: 67.7799607073%;
  font-size: 18px;
  filter: blur(0.12px);
  animation: 35s floatReverse2 infinite;
}
.particle:nth-child(7) {
  top: 55.5420219245%;
  left: 19.5886385896%;
  font-size: 21px;
  filter: blur(0.14px);
  animation: 23s float2 infinite;
}
.particle:nth-child(8) {
  top: 73.2530120482%;
  left: 33.9805825243%;
  font-size: 30px;
  filter: blur(0.16px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(9) {
  top: 74.2368742369%;
  left: 11.7762512267%;
  font-size: 19px;
  filter: blur(0.18px);
  animation: 38s floatReverse infinite;
}
.particle:nth-child(10) {
  top: 66.0194174757%;
  left: 88.8671875%;
  font-size: 24px;
  filter: blur(0.2px);
  animation: 22s floatReverse infinite;
}
.particle:nth-child(11) {
  top: 70.5018359853%;
  left: 41.2979351032%;
  font-size: 17px;
  filter: blur(0.22px);
  animation: 38s float2 infinite;
}
.particle:nth-child(12) {
  top: 32.0388349515%;
  left: 60.546875%;
  font-size: 24px;
  filter: blur(0.24px);
  animation: 30s floatReverse2 infinite;
}
.particle:nth-child(13) {
  top: 26.9879518072%;
  left: 15.5339805825%;
  font-size: 30px;
  filter: blur(0.26px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(14) {
  top: 48.5436893204%;
  left: 27.34375%;
  font-size: 24px;
  filter: blur(0.28px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(15) {
  top: 37.3006134969%;
  left: 9.8522167488%;
  font-size: 15px;
  filter: blur(0.3px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(16) {
  top: 43.8489646772%;
  left: 47.0127326151%;
  font-size: 21px;
  filter: blur(0.32px);
  animation: 30s float2 infinite;
}
.particle:nth-child(17) {
  top: 30.9927360775%;
  left: 16.5692007797%;
  font-size: 26px;
  filter: blur(0.34px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(18) {
  top: 3.8787878788%;
  left: 27.3170731707%;
  font-size: 25px;
  filter: blur(0.36px);
  animation: 28s float2 infinite;
}
.particle:nth-child(19) {
  top: 63.1067961165%;
  left: 92.7734375%;
  font-size: 24px;
  filter: blur(0.38px);
  animation: 35s float2 infinite;
}
.particle:nth-child(20) {
  top: 5.8536585366%;
  left: 87.2549019608%;
  font-size: 20px;
  filter: blur(0.4px);
  animation: 33s floatReverse2 infinite;
}
.particle:nth-child(21) {
  top: 16.5450121655%;
  left: 68.4931506849%;
  font-size: 22px;
  filter: blur(0.42px);
  animation: 24s float infinite;
}
.particle:nth-child(22) {
  top: 47.2289156627%;
  left: 61.1650485437%;
  font-size: 30px;
  filter: blur(0.44px);
  animation: 36s floatReverse infinite;
}
.particle:nth-child(23) {
  top: 46.8864468864%;
  left: 33.3660451423%;
  font-size: 19px;
  filter: blur(0.46px);
  animation: 26s floatReverse2 infinite;
}
.particle:nth-child(24) {
  top: 34.398034398%;
  left: 98.6193293886%;
  font-size: 14px;
  filter: blur(0.48px);
  animation: 22s float2 infinite;
}
.particle:nth-child(25) {
  top: 29.0909090909%;
  left: 11.7073170732%;
  font-size: 25px;
  filter: blur(0.5px);
  animation: 35s floatReverse2 infinite;
}
.particle:nth-child(26) {
  top: 18.4690157959%;
  left: 21.5053763441%;
  font-size: 23px;
  filter: blur(0.52px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(27) {
  top: 69.3528693529%;
  left: 47.1050049068%;
  font-size: 19px;
  filter: blur(0.54px);
  animation: 32s float infinite;
}
.particle:nth-child(28) {
  top: 49.0196078431%;
  left: 29.5275590551%;
  font-size: 16px;
  filter: blur(0.56px);
  animation: 37s floatReverse2 infinite;
}
.particle:nth-child(29) {
  top: 62.2871046229%;
  left: 89.0410958904%;
  font-size: 22px;
  filter: blur(0.58px);
  animation: 37s float2 infinite;
}
.particle:nth-child(30) {
  top: 83.0280830281%;
  left: 37.2914622179%;
  font-size: 19px;
  filter: blur(0.6px);
  animation: 25s float2 infinite;
}
.particle:nth-child(31) {
  top: 79.802955665%;
  left: 87.9446640316%;
  font-size: 12px;
  filter: blur(0.62px);
  animation: 29s float2 infinite;
}
.particle:nth-child(32) {
  top: 68.7651331719%;
  left: 18.5185185185%;
  font-size: 26px;
  filter: blur(0.64px);
  animation: 34s floatReverse2 infinite;
}
.particle:nth-child(33) {
  top: 9.7442143727%;
  left: 24.485798237%;
  font-size: 21px;
  filter: blur(0.66px);
  animation: 23s float2 infinite;
}
.particle:nth-child(34) {
  top: 92.1568627451%;
  left: 45.2755905512%;
  font-size: 16px;
  filter: blur(0.68px);
  animation: 22s floatReverse2 infinite;
}
.particle:nth-child(35) {
  top: 90.931076179%;
  left: 37.9746835443%;
  font-size: 27px;
  filter: blur(0.7px);
  animation: 29s floatReverse2 infinite;
}
.particle:nth-child(36) {
  top: 8.8779284834%;
  left: 98.9119683482%;
  font-size: 11px;
  filter: blur(0.72px);
  animation: 32s float infinite;
}
.particle:nth-child(37) {
  top: 37.7267230955%;
  left: 64.264849075%;
  font-size: 27px;
  filter: blur(0.74px);
  animation: 24s float2 infinite;
}
.particle:nth-child(38) {
  top: 52.1739130435%;
  left: 11.673151751%;
  font-size: 28px;
  filter: blur(0.76px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(39) {
  top: 95.0980392157%;
  left: 31.4960629921%;
  font-size: 16px;
  filter: blur(0.78px);
  animation: 34s floatReverse2 infinite;
}
.particle:nth-child(40) {
  top: 74.4860943168%;
  left: 7.7896786758%;
  font-size: 27px;
  filter: blur(0.8px);
  animation: 23s float infinite;
}
.particle:nth-child(41) {
  top: 28.6066584464%;
  left: 3.9564787339%;
  font-size: 11px;
  filter: blur(0.82px);
  animation: 33s float infinite;
}
.particle:nth-child(42) {
  top: 39.5180722892%;
  left: 53.3980582524%;
  font-size: 30px;
  filter: blur(0.84px);
  animation: 38s floatReverse2 infinite;
}
.particle:nth-child(43) {
  top: 13.8101109741%;
  left: 11.8694362018%;
  font-size: 11px;
  filter: blur(0.86px);
  animation: 24s floatReverse2 infinite;
}
.particle:nth-child(44) {
  top: 76.2836185819%;
  left: 69.7445972495%;
  font-size: 18px;
  filter: blur(0.88px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(45) {
  top: 10.7843137255%;
  left: 92.5196850394%;
  font-size: 16px;
  filter: blur(0.9px);
  animation: 32s floatReverse2 infinite;
}
.particle:nth-child(46) {
  top: 81.3725490196%;
  left: 63.9763779528%;
  font-size: 16px;
  filter: blur(0.92px);
  animation: 33s float infinite;
}
.particle:nth-child(47) {
  top: 89.544895449%;
  left: 62.1915103653%;
  font-size: 13px;
  filter: blur(0.94px);
  animation: 24s floatReverse2 infinite;
}
.particle:nth-child(48) {
  top: 68.2926829268%;
  left: 5.8823529412%;
  font-size: 20px;
  filter: blur(0.96px);
  animation: 33s floatReverse infinite;
}
.particle:nth-child(49) {
  top: 25.5528255528%;
  left: 37.4753451677%;
  font-size: 14px;
  filter: blur(0.98px);
  animation: 36s floatReverse infinite;
}
.particle:nth-child(50) {
  top: 35.8787878788%;
  left: 88.7804878049%;
  font-size: 25px;
  filter: blur(1px);
  animation: 31s floatReverse2 infinite;
}
.particle:nth-child(51) {
  top: 86.6180048662%;
  left: 85.1272015656%;
  font-size: 22px;
  filter: blur(1.02px);
  animation: 27s floatReverse infinite;
}
.particle:nth-child(52) {
  top: 9.7919216646%;
  left: 22.6155358899%;
  font-size: 17px;
  filter: blur(1.04px);
  animation: 21s floatReverse infinite;
}
.particle:nth-child(53) {
  top: 10.6280193237%;
  left: 71.9844357977%;
  font-size: 28px;
  filter: blur(1.06px);
  animation: 37s float2 infinite;
}
.particle:nth-child(54) {
  top: 49.0797546012%;
  left: 73.8916256158%;
  font-size: 15px;
  filter: blur(1.08px);
  animation: 21s floatReverse2 infinite;
}
.particle:nth-child(55) {
  top: 6.7714631197%;
  left: 6.8159688413%;
  font-size: 27px;
  filter: blur(1.1px);
  animation: 36s float infinite;
}
.particle:nth-child(56) {
  top: 57.8431372549%;
  left: 97.4409448819%;
  font-size: 16px;
  filter: blur(1.12px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(57) {
  top: 56.5853658537%;
  left: 89.2156862745%;
  font-size: 20px;
  filter: blur(1.14px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(58) {
  top: 87.8166465621%;
  left: 41.788143829%;
  font-size: 29px;
  filter: blur(1.16px);
  animation: 23s float2 infinite;
}
.particle:nth-child(59) {
  top: 24.6609124538%;
  left: 31.6518298714%;
  font-size: 11px;
  filter: blur(1.18px);
  animation: 30s float infinite;
}
.particle:nth-child(60) {
  top: 59.4397076736%;
  left: 64.6425073457%;
  font-size: 21px;
  filter: blur(1.2px);
  animation: 39s floatReverse infinite;
}
.particle:nth-child(61) {
  top: 32.810615199%;
  left: 86.491739553%;
  font-size: 29px;
  filter: blur(1.22px);
  animation: 36s float infinite;
}
.particle:nth-child(62) {
  top: 82.4539877301%;
  left: 49.2610837438%;
  font-size: 15px;
  filter: blur(1.24px);
  animation: 27s float2 infinite;
}
.particle:nth-child(63) {
  top: 55.9509202454%;
  left: 53.2019704433%;
  font-size: 15px;
  filter: blur(1.26px);
  animation: 23s float infinite;
}
.particle:nth-child(64) {
  top: 13.7761377614%;
  left: 35.538005923%;
  font-size: 13px;
  filter: blur(1.28px);
  animation: 22s float infinite;
}
.particle:nth-child(65) {
  top: 93.6067551267%;
  left: 44.703595724%;
  font-size: 29px;
  filter: blur(1.3px);
  animation: 33s floatReverse2 infinite;
}
.particle:nth-child(66) {
  top: 98.2800982801%;
  left: 67.0611439842%;
  font-size: 14px;
  filter: blur(1.32px);
  animation: 27s floatReverse infinite;
}
.particle:nth-child(67) {
  top: 60.2673147023%;
  left: 26.3929618768%;
  font-size: 23px;
  filter: blur(1.34px);
  animation: 27s float2 infinite;
}
.particle:nth-child(68) {
  top: 27.9518072289%;
  left: 71.8446601942%;
  font-size: 30px;
  filter: blur(1.36px);
  animation: 40s floatReverse2 infinite;
}
.particle:nth-child(69) {
  top: 15.7829839704%;
  left: 47.4777448071%;
  font-size: 11px;
  filter: blur(1.38px);
  animation: 24s float2 infinite;
}
.particle:nth-child(70) {
  top: 39.5180722892%;
  left: 23.3009708738%;
  font-size: 30px;
  filter: blur(1.4px);
  animation: 36s floatReverse2 infinite;
}
.particle:nth-child(71) {
  top: 66.0912453761%;
  left: 19.7823936696%;
  font-size: 11px;
  filter: blur(1.42px);
  animation: 38s float2 infinite;
}
.particle:nth-child(72) {
  top: 88.6723507917%;
  left: 37.2184133203%;
  font-size: 21px;
  filter: blur(1.44px);
  animation: 32s float infinite;
}
.particle:nth-child(73) {
  top: 18.401937046%;
  left: 74.0740740741%;
  font-size: 26px;
  filter: blur(1.46px);
  animation: 33s floatReverse infinite;
}
.particle:nth-child(74) {
  top: 16.4053075995%;
  left: 25.267249757%;
  font-size: 29px;
  filter: blur(1.48px);
  animation: 30s floatReverse infinite;
}
.particle:nth-child(75) {
  top: 87.3619631902%;
  left: 29.5566502463%;
  font-size: 15px;
  filter: blur(1.5px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(76) {
  top: 81.8514007308%;
  left: 55.8276199804%;
  font-size: 21px;
  filter: blur(1.52px);
  animation: 26s float infinite;
}
.particle:nth-child(77) {
  top: 0.9840098401%;
  left: 95.7551826259%;
  font-size: 13px;
  filter: blur(1.54px);
  animation: 25s float infinite;
}
.particle:nth-child(78) {
  top: 84.8780487805%;
  left: 4.9019607843%;
  font-size: 20px;
  filter: blur(1.56px);
  animation: 24s float2 infinite;
}
.particle:nth-child(79) {
  top: 96.2332928311%;
  left: 10.752688172%;
  font-size: 23px;
  filter: blur(1.58px);
  animation: 29s float infinite;
}
.particle:nth-child(80) {
  top: 40.6779661017%;
  left: 35.0877192982%;
  font-size: 26px;
  filter: blur(1.6px);
  animation: 24s floatReverse2 infinite;
}

@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(180px);
  }
}
@keyframes floatReverse {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-180px);
  }
}
@keyframes float2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(28px);
  }
}
@keyframes floatReverse2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-28px);
  }
}
